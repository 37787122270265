import { createSlice } from "@reduxjs/toolkit";
import { LOGIN_PLATFORM_SEMRUSH, SEMRUSH_HOST } from "../../../utils/constants";

let initLoginPlatform = "";
let adminUser = false;
let templateCreatorPermission = 0;
try {
  initLoginPlatform = localStorage.getItem("login_platform");
  adminUser = localStorage.getItem("isAdminUser") === "true";
  templateCreatorPermission = JSON.parse(localStorage.getItem("templateCreatorPermission"));
} catch (error) {
  const currentURL = new URL(window.location.href);
  if (currentURL.host === SEMRUSH_HOST) {
    initLoginPlatform = LOGIN_PLATFORM_SEMRUSH;
  }
}

const initialState = {
  planId: "",
  planExpired: false,
  limitReached: false,
  loginPlatform: initLoginPlatform,
  embedMode: "",
  libLimitReached: false,
  libCreditsAvail: false,
  teamMemberLimitReached: false,
  isAdminUser: adminUser,
  workspaceAvailableLimit: 0,
  pdfStates: {},
  isPrintPdf: false,
  accountsLinkingExceeded: false,
  userPlanDetails: {
    planPaused: false // TODO MAke Default False
    // planPaused: true,
  },
  sessionExpired: false,
  voLimitReached: false,
  templateCreatorPermission: templateCreatorPermission,
  isUsingEcomServices: false,
  ecom_user: false
};

const setIsPrintPdf = (state, action) => {
  state.setIsPrintPdf = action.payload;
};
const setTeamMemberLimitReached = (state, action) => {
  state.teamMemberLimitReached = action.payload;
};
const setAccountsLinkingExceeded = (state, action) => {
  state.accountsLinkingExceeded = action.payload;
};
const setIsAdminUser = (state, action) => {
  state.isAdminUser = action.payload;
};
const setTemplateCreatorPermission = (state, action) => {
  state.templateCreatorPermission = action.payload;
};
const setLibLimitReached = (state, action) => {
  state.libLimitReached = action.payload;
};
const setLibCreditsAvail = (state, action) => {
  state.libCreditsAvail = action.payload;
};
const setCurrentPlan = (state, action) => {
  state.planId = action.payload;
};
const setLimitReached = (state, action) => {
  state.limitReached = action.payload;
};
const setPlanDetails = (state, action) => {
  state.userPlanDetails[action.key] = action.payload;
};
const setPdfStates = (state, action) => {
  state.pdfStates = action.payload;
};
const setPlanExpired = (state, action) => {
  state.planExpired = action.payload;
};
const setLoginPlatform = (state, action) => {
  state.loginPlatform = action.payload;
};
const setWorkspaceAvailableLimit = (state, action) => {
  state.workspaceAvailableLimit = action.payload;
};
const setSessionExpired = (state, action) => {
  state.sessionExpired = action.payload;
};
const setVOLimitReached = (state, action) => {
  state.voLimitReached = action.payload;
};

const setEcomUser = (state, action) => {
  state.ecom_user = action.payload;
};
const setEmbedMode = (state, action) => {
  state.embedMode = action.payload;
};

const getUserData = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserJobPlan: setCurrentPlan,
    setPlanExpired: setPlanExpired,
    setLoginPlatform,
    setWorkspaceAvailableLimit,
    setPdfStates,
    setIsPrintPdf,
    setIsAdminUser,
    setLimitReached,
    setLibLimitReached,
    setLibCreditsAvail,
    setVOLimitReached,
    setTeamMemberLimitReached,
    setPlanDetails,
    setAccountsLinkingExceeded,
    setSessionExpired,
    setTemplateCreatorPermission,
    setEcomUser,
    setEmbedMode
  }
});

export default getUserData.reducer;
